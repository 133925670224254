import { firestore } from '../../firebase'

export const getNondeletedArtistsObject = async () => {
  const artistsObj = {}
  try {
    const artistsSnapshot = await firestore
      .collection('artists')
      // .where('first_name', '==', 'Ilze')
      .get()
    if (artistsSnapshot.empty) {
      console.log('No matching documents.')
      return
    }
    artistsSnapshot.forEach((doc) => {
      const docData = doc.data()
      const docId = doc.id
      if (!docData.deleted) {
        artistsObj[docId] = docData
      }
    })
    return artistsObj
  } catch (error) {
    console.error(error)
  }
}

export const getNondeletedArtworksArrayPerFair = async (fair) => {
  const artworksArray: Array<any> = []
  try {
    const artworksSnapshot = await firestore
      .collection('artworks')
      .where('fair', '==', fair)
      .get()
    if (artworksSnapshot.empty) {
      console.log('No matching documents.')
      return
    }
    artworksSnapshot.forEach((doc) => {
      const docData = doc.data()
      const docId = doc.id
      if (!docData.deleted) {
        artworksArray.push({ id: docId, ...docData })
      }
    })
    return artworksArray
  } catch (error) {
    console.error(error)
  }
}