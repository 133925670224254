import { groupByKey } from '../helpers'
import { currentFair } from './zipExport'

export const transformArtistsObj = async (artistsObj, artworksArray) => {
  const artworksByArtist = groupByKey(artworksArray, 'artistId')
  console.log('artworksByArtist', artworksByArtist)
  
  const filteredArtistsObj: Record<string, any> = Object.entries(artistsObj).filter(([docId]) =>
    Object.keys(artworksByArtist).includes(docId)
  )
  console.log(filteredArtistsObj.length)

  
  const result = filteredArtistsObj
  .reduce((artistObj, [docId, artistData]) => {
      const artistsArtworksByFair = groupByKey(artworksByArtist[docId], 'fair')

      const {
        done212,
        done21,
        userAgent,
        id,
        previewImage,
        // ContractData:
        account_holder,
        iban,
        bic,
        bank_name,
        tax_number,
        tax_office,
        liability,
        consentGTC,
        // unneeded data for urgent temp solution
        // vita_short,
        // vita_long,
        birthday,
        instagram,
        email,
        homepage,
        info,
        ...restArtistData
      } = artistData

      artistObj[docId] = {
        ...restArtistData,
        artworks: artistsArtworksByFair[currentFair].map(
          (artwork) => {
            const { title, files, catalog = false, catalogTop = false } = artwork
            if (!files[0] || !title) {
              console.warn('No files for', artwork)
              return null
            }

            return {
            title,
            path: files[0],
            finalName: `${artistData.last_name} ${artistData.first_name}/${
              catalogTop ? 'Katalog Gross/XXX-' : catalog ? 'Katalog/X-' : ''
            }${title.replace('/','_')}.${files[0].split('.').pop()}`,
          }
          }
        ).filter((artwork) => artwork !== null),
      }
      return artistObj
    }, {})

  return result
}
