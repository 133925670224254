import * as React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  email,
  FileInput,
  BooleanField,
  NumberField,
  // FileField,
} from 'react-admin'
import { Box, Typography } from '@material-ui/core'

const requiredValidate = [required()]

// eslint-disable-next-line no-unused-vars
export const validatePasswords = ({ password, confirm_password }) => {
  const errors = {}
  // if (password && confirm_password && password !== confirm_password) {
  //   errors.confirm_password = ['resources.customers.errors.password_mismatch']
  // }
  return errors
}

export const CreateImageField = ({ record, source }) => {
  console.log(record, source)
  if (!record[source]) return null
  return (
    <img
      src={record[source]}
      // src={fbImageFolder + record[source] + '?alt=media'}
      style={{ height: '50px', width: 'auto' }}
      alt="Preview"
    />
  )
}

export const SharedFields = () => {
  return (
    <Box display="flex">
      <Box flex={3} mr="3em">
        <Box display="flex">
          <Box flex={1} mr="0">
            <TextInput
              source="last_name"
              label="Nachname"
              fullWidth
              validate={requiredValidate}
            />
          </Box>
          <Box flex={1} ml="0.5em">
            <TextInput
              source="first_name"
              label="Vorname"
              fullWidth
              validate={requiredValidate}
            />
          </Box>
          <Box flex={1} ml="0.5em">
            <TextInput source="pseudonym" label="Künstlername" fullWidth />
          </Box>
        </Box>
        <Box display="flex">
          <Box flex={1} ml="0">
            <TextInput
              type="email"
              label="E-Mail"
              source="email"
              validation={{ email: true }}
              fullWidth
              validate={[required(), email()]}
            />
          </Box>
          <Box flex={1} ml="0.5em">
            <TextInput
              source="homepage"
              label="Homepage / Social Media"
              fullWidth
            />
          </Box>
          <Box flex={1} ml="0.5em">
            <TextInput
              source="birthday"
              label="Geburtstag (dd.mm.yyyy)"
              fullWidth
              validate={requiredValidate}
            />
          </Box>
        </Box>
        <Box display="flex">
          <Box flex={1} ml="0">
            <TextInput
              source="address"
              label="Straße & Nr."
              fullWidth
              validate={requiredValidate}
            />
          </Box>
          <Box flex={1} ml="0.5em">
            <TextInput
              source="zipcode"
              label="PLZ"
              fullWidth
              validate={requiredValidate}
            />
          </Box>
          <Box flex={1} ml="0.5em">
            <TextInput
              source="city"
              label="Stadt"
              fullWidth
              validate={requiredValidate}
            />
          </Box>
        </Box>
        <Box display="flex">
          <Box flex={1} ml="0">
            <TextInput
              source="mobile"
              label="Handy"
              fullWidth
              validate={requiredValidate}
            />
          </Box>
          <Box flex={1} ml="0.5em">
            <TextInput source="phone" label="Festnetz" fullWidth />
          </Box>
        </Box>
        <Box display="flex">
          <TextInput
            source="info"
            label="Info / Hinweise"
            multiline
            fullWidth
          />
        </Box>
        <Box display="flex">
          <FileInput
            source="portrait_file"
            label="Portraitfoto"
            validate={requiredValidate}
            fullWidth
            options={{
              // onDropAccepted,
              // onDropRejected,
              accept: ['image/jpeg, image/png'],
            }}
          >
            <CreateImageField source="src" />
          </FileInput>
        </Box>
        <Box
          display="flex"
          mt="3em"
          style={{ justifyContent: 'space-between' }}
        >
          <Box ml="0" style={{ alignItems: 'center' }}>
            <Typography variant="h6">Bank-/Steuerdaten</Typography>
          </Box>
        </Box>
        <Box display="flex" my="0.5em">
          {/* <Box display="flex" ml="0.5em">
            <BooleanField source="consentGTC_20231" fullWidth />
            <Typography variant="p">Vertrag akzeptiert</Typography>
          </Box> */}
          <Box display="flex" ml="0.5em">
            <BooleanField source="consentContract" fullWidth />
            <Typography variant="p">Vertrag akzep.</Typography>
          </Box>
          <Box display="flex" ml="0.5em">
            <BooleanField source="consentInsurance" fullWidth />
            <Typography variant="p">Versicherung</Typography>
          </Box>
          <Box display="flex" ml="0.5em">
            <NumberField source="infoCards" fullWidth />
            <Typography variant="p">Infokarten</Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Box flex={1} ml="0">
            <TextInput source="tax_number" label="Steuernummer" fullWidth />
          </Box>
          <Box flex={1} ml="0.5em">
            <TextInput source="tax_office" label="Finanzamt" fullWidth />
          </Box>
          <Box flex={1} ml="0.5em">
            <TextInput
              source="liability"
              label="Mwst-pflichtig"
              fullWidth
              disabled
            />
          </Box>
        </Box>
        <Box display="flex">
          <Box flex={1} ml="0">
            <TextInput source="account_holder" label="Kontoinhaber" fullWidth />
          </Box>
          <Box flex={1} ml="0.5em">
            <TextInput source="bank_name" label="Bankname" fullWidth />
          </Box>
        </Box>
        <Box display="flex">
          <Box flex={1} ml="0">
            <TextInput source="iban" label="IBAN" fullWidth />
          </Box>
          <Box flex={1} ml="0.5em">
            <TextInput source="bic" label="BIC" fullWidth />
          </Box>
        </Box>
      </Box>
      <Box flex={2}>
        <NumberInput
          source="previewImage"
          label="Galerie-Vorschaubild (Index von Artworks)"
          fullWidth
        />
        <TextInput
          source="vita_short"
          label="Kurzvita"
          resettable
          multiline
          fullWidth
          validate={requiredValidate}
        />
        <TextInput
          source="vita_long"
          label="Komplette Vita"
          resettable
          multiline
          fullWidth
          validate={requiredValidate}
        />
      </Box>
    </Box>
  )
}

const ArtistCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm validate={validatePasswords}>
        <Typography variant="h6" gutterBottom>
          Neuen Künstler erstellen
        </Typography>
        <SharedFields />
      </SimpleForm>
    </Create>
  )
}

export default ArtistCreate
