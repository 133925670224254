import * as React from 'react'
import { useCallback } from 'react'
import classnames from 'classnames'
import { Route, useHistory } from 'react-router-dom'
import { Drawer } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import {
  CardActions,
  CreateButton,
  ExportButton,
  List,
  // Create,
  Datagrid,
  TextField,
  BooleanField,
  NumberField,
  ReferenceField,
  EditButton,
  Filter,
  SelectInput,
  TextInput,
  ReferenceInput,
  DateField,
} from 'react-admin'
import ImageField from '../shared/ImageField'
import { exportToXLSX, getArtworkDimension } from '../shared/helpers'
import ArtistLinkField from './ArtistLinkField'
import ArtworkEditRight from './ArtworkEditRight'
import ShortTextField from '../shared/ShortTextField'

export const artworksExporter = (records, fetchRelatedRecords) => {
  const fairToExport = '2024-1'
  fetchRelatedRecords(records, 'artistId', 'artists').then((artists) => {
    const recordsForExport = records
      .filter((record) => record.fair === fairToExport)
      .filter((record) => record.deleted !== true)
      .map((record) => {
        const {
          // omit fields:
          id,
          // artistId, // long firbase ID IMPORTANT because of try{} later
          artworkIndex,
          applicationYear,
          fair,
          file,
          files,
          mainImage,
          // visible,
          catalog,
          catalogTop,
          hidden,
          createdAt,
          createdby,
          createdate,
          updatedAt,
          updatedby,
          lastupdate,
          // temp:
          trackArtworkModalOpen,
          trackArtworkView,
          ...rest
        } = record
        // postForExport.author_name = post.author.name; // add a field
        // console.log(record, artists[record.artistId])
        try {
          rest.artist_id = artists[record.artistId]?.artistId || '-'
          rest.last_name = artists[record.artistId]?.last_name || '-'
          rest.first_name = artists[record.artistId]?.first_name || '-'
          rest.dimensions = getArtworkDimension(rest)
        } catch (error) {
          console.warn(error, record, artists[record.artistId])
        }
        if (!artists[record.artistId]) {
          console.warn('No artist found for', record.artistId, record)
          return null
        } else {
        return rest
        }
      })
      .filter((record) => record !== null)

    console.info('recordsForExport:',recordsForExport)

    exportToXLSX(
      recordsForExport.sort((a, b) => a.last_name.localeCompare(b.last_name)),
      [
        // order fields in the export
        'last_name',
        'first_name',
        'artist_id',
        'visible',
        // 'catalog',
        // 'catalogTop',
        'title',
        'technique',
        'dimensions',
        'height',
        'width',
        'depth',
        'price',
        'porto',
        'portoActive',
        // 'trackArtworkView',
        // 'trackArtworkModalOpen',
        'info',
        'annotation',
      ],
      'artworks',
      `KUBOSHOW ${fairToExport} GDT Arbeiten ${new Date().toISOString()}`
    )
  })
}

export const ArtworkListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Suche Titel" source="title" alwaysOn />
    <ReferenceInput
      label="Künstler Filter"
      source="artistId"
      reference="artists"
      emptyText="-----"
      perPage={500}
      sort={{ field: 'last_name', order: 'ASC' }}
      allowEmpty
      alwaysOn
    >
      <SelectInput
        optionText={(record) => `${record.last_name} ${record.first_name}`}
      />
    </ReferenceInput>
    <SelectInput
      label="Ausgabe"
      source="fair"
      choices={[
        { id: '2024-1', name: '2024-1' },
        { id: '2023-2', name: '2023-2' },
        { id: '2023-1', name: '2023-1' },
        { id: '2022-2', name: '2022-2' },
        { id: '2022-1', name: '2022-1' },
        { id: '2021-2', name: '2021-2' },
        { id: '2021-1', name: '2021-1' },
        { id: '2020', name: '2020' },
      ]}
      alwaysOn
    />
  </Filter>
)

const ArtworkListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
  </CardActions>
)
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 400,
  },
  drawerPaper: {
    zIndex: 100,
  },
}))

export const OnlineOfflineField = ({ record, source }) => (
  <span style={{ color: record[source] === true ? '#2c9e7a' : '#dc3545' }}>
    {record[source] === true ? 'Online' : 'Offline'}
  </span>
)

const ArtworkList = (props) => {
  const classes = useStyles()
  const history = useHistory()

  const handleClose = useCallback(() => {
    history.push('/artworks')
  }, [history])

  return (
    <div className={classes.root}>
      <Route path="/artworks/:id">
        {({ match }) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== 'create'
          )

          return (
            <>
              <List
                {...props}
                perPage={100}
                filters={<ArtworkListFilter />}
                filterDefaultValues={{ fair: '2024-1' }}
                sort={{ field: 'updatedAt', order: 'DESC' }}
                actions={<ArtworkListActions />}
                exporter={artworksExporter}
                className={classnames(classes.list, {
                  [classes.listWithDrawer]: isMatch,
                })}
                // bulkActionButtons={<ReviewsBulkActionButtons />}
              >
                <Datagrid rowClick="edit" optimized>
                  <TextField disabled label="Ausgabe" source="fair" />
                  <NumberField label="Index" disabled source="artworkIndex" />
                  <ImageField label="Bilder" source="files" />
                  <ReferenceField
                    label="Künstler"
                    source="artistId"
                    reference="artists"
                  >
                    <ArtistLinkField />
                  </ReferenceField>
                  <OnlineOfflineField label="On-/Offline" source="visible" />
                  {/* <NumberField
                    label="Bild geöffnet"
                    disabled
                    source="trackArtworkView"
                  />
                  <NumberField
                    label="KA geöffnet"
                    disabled
                    source="trackArtworkModalOpen"
                  /> */}
                  <BooleanField label="Katalog" source="catalog" />
                  <BooleanField label="Katalog Top" source="catalogTop" />
                  <TextField label="Titel" source="title" />
                  <TextField label="Technik" source="technique" />
                  <TextField label="Höhe" source="height" />
                  <TextField label="Breite" source="width" />
                  <TextField label="Tiefe" source="depth" />
                  <TextField label="Preis" source="price" />
                  <TextField label="Porto" source="porto" />
                  <BooleanField label="Porto aktiv" source="portoActive" />
                  <ShortTextField label="Künstlerhinweis" source="info" />
                  <ShortTextField label="Vermerk" source="annotation" />
                  <DateField
                    source="createdAt"
                    label="erstellt"
                    showTime={true}
                  />
                  <DateField
                    source="updatedAt"
                    label="letzte Aktualisierung"
                    showTime={true}
                  />
                  <EditButton />
                </Datagrid>
              </List>
              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={handleClose}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                {isMatch ? (
                  <ArtworkEditRight
                    id={match.params.id}
                    onCancel={handleClose}
                    {...props}
                  />
                ) : null}
              </Drawer>
            </>
          )
        }}
      </Route>
    </div>
  )
}

export default ArtworkList
