import * as React from 'react'
import {
  useEditController,
  EditContextProvider,
  useTranslate,
  useReference,
  TextInput,
  SimpleForm,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  BooleanInput,
  SelectInput,
  required,
  TextField,
  NumberField,
  NullableBooleanInput,
  //   DateField,
} from 'react-admin'
import { IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import InstagramIcon from '@material-ui/icons/Instagram'
import { copyTextToClipboard2, getInstaText } from '../shared/helpers'

// import ProductReferenceField from '../products/ProductReferenceField'
// import CustomerReferenceField from '../visitors/CustomerReferenceField'
// import StarRatingField from './StarRatingField'
// import ReviewEditToolbar from './ReviewEditToolbar'

const requiredValidate = [required()]

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em',
    marginBottom: '0',
  },
  form: {
    [theme.breakpoints.up('xs')]: {
      width: 600,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginTop: -30,
    },
  },
  inlineField: {
    display: 'inline-block',
    width: '50%',
  },
}))

const ReviewEdit = ({ onCancel, ...props }) => {
  const [copiedSuccesfully, setCopiedSuccesfully] = React.useState(false)
  const classes = useStyles()
  const controllerProps = useEditController(props)
  // const translate = useTranslate()

  const reference = useReference({
    id: controllerProps.record.artistId,
    reference: 'artists',
  })

  console.log(reference)
  if (!controllerProps.record) {
    return null
  }
  const copyArtworkToClipboard = (artist, artwork) => {
    const instaText = getInstaText(artist, artwork)
    copyTextToClipboard2(instaText).then(() => {
      setCopiedSuccesfully(true)
      console.log('copied: ' + instaText)
    })
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">Artwork bearbeiten</Typography>
        <IconButton
          onClick={() =>
            copyArtworkToClipboard(
              reference.referenceRecord,
              controllerProps.record
            )
          }
        >
          <InstagramIcon
            // htmlColor="yellow"
            htmlColor={copiedSuccesfully ? 'darkgreen' : 'inherit'}
          />
        </IconButton>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <EditContextProvider value={controllerProps}>
        <SimpleForm
          className={classes.form}
          basePath={controllerProps.basePath}
          record={controllerProps.record}
          save={controllerProps.save}
          version={controllerProps.version}
          redirect="list"
          resource="artworks"
          //   toolbar={<ReviewEditToolbar />}
        >
          {/* <TextField formClassName={classes.inlineField} source="id" /> */}
          <BooleanInput
            formClassName={classes.inlineField}
            label="On-/Offline"
            initialValue={false}
            source="visible"
          />
          <div style={{ display: 'flex', width: '100%', gap: '0 5px' }}>
            <div style={{ marginRight: '20px' }}>
              <p style={{ margin: '0' }}>Index: </p>
              <NumberField
                formClassName={classes.inlineField}
                label="Artwork Index"
                validate={requiredValidate}
                source="artworkIndex"
              />
            </div>
            <BooleanInput
              formClassName={classes.inlineField}
              label="Katalog"
              // initialValue={false}
              source="catalog"
            />
            <BooleanInput
              formClassName={classes.inlineField}
              label="Katalog Top"
              // initialValue={false}
              source="catalogTop"
            />
          </div>
          {/* <ReferenceInput
            label="Künstler"
            source="artistId"
            reference="artists"
            emptyText="-----"
            perPage={500}
            sort={{ field: 'last_name', order: 'ASC' }}
            fullWidth
            validate={requiredValidate}
          >
            <SelectInput
              optionText={(record) =>
                `${record.last_name} ${record.first_name}`
              }
            />
          </ReferenceInput> */}
          <div style={{ display: 'flex', width: '100%', gap: '0 5px' }}>
            <TextInput
              source="title"
              label="Titel"
              validate={requiredValidate}
            />
            <TextInput
              source="technique"
              label="Technik"
              validate={requiredValidate}
            />
          </div>
          <div style={{ display: 'flex', width: '100%', gap: '0 5px' }}>
            <TextInput
              source="height"
              label="Höhe in cm"
              validate={requiredValidate}
            />
            <TextInput
              source="width"
              label="Breite in cm"
              validate={requiredValidate}
            />
            <TextInput source="depth" label="Tiefe in cm" />
          </div>
          <div style={{ display: 'flex', width: '100%', gap: '0 5px' }}>
            <TextInput
              source="price"
              label="Preis"
              fullWidth
              validate={requiredValidate}
            />
            <NumberInput
              source="porto"
              label="Porto"
              fullWidth
              // validate={requiredValidate}
              formClassName={classes.inlineField}
            />
            <BooleanInput
              formClassName={classes.inlineField}
              label="Porto aktiv"
              source="portoActive"
            />
          </div>
          <div style={{ display: 'flex', width: '100%', gap: '0 5px' }}>
            <TextInput source="info" label="Künstlerhinweis" fullWidth />

            <TextInput source="annotation" label="Vermerk" fullWidth />
          </div>
          <ArrayInput
            source="files"
            label="Bilder"
            // validate={requiredValidate}
            fullWidth
          >
            <SimpleFormIterator>
              {/* <FileInput
                // label="Bilder"
                // validate={requiredValidate}
                // fullWidth
                options={{
                  // onDropAccepted,
                  // onDropRejected,
                  accept: ['image/jpeg, image/png'],
                }}
              >
                <CreateImageField />
              </FileInput> */}
              <TextInput />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </EditContextProvider>
    </div>
  )
}

export default ReviewEdit
